import /*Vue, */{createApp, ref} from 'vue';
import MyApp from './App.vue';

import '@/assets/css/reset.css';
import '@/scss/kmm-v1.scss';
import '@/assets/css/kmm.css';
import '@/assets/css/qip.css';

/** global isWaitXhr:writable, var2:writable */

const app = createApp(MyApp, {
    /*provide: {
        someProperty: 0,
    }*/
});

//app.provide('someProperty', 0);
//app.someProperty = ref(0);

app.use(require('@/plugins/notify').default, {/*options*/});

import { createPinia } from 'pinia';
const pinia = createPinia();
app.use(pinia);
import register from '@/components/register';
register(app);

import {createRouter} from "@/router";
const redirectStore = {
    redirect: false
};
const requestStore = {};
const router = createRouter(app, pinia, redirectStore, requestStore);
app.use(router);

app.use(require('@/plugins/freshness').default, {router});

import useStore from '@/store';
const store = useStore()
app.config.globalProperties.$store = store;

app.use(require('@/axios-use').default, {/*options*/});

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-purple/theme.css';

app.use(PrimeVue, {/*unstyled: true*/});

/*const vm = */app.mount('#app');
