<script setup>
import AppLayoutDefault from './AppLayoutDefault.vue';
import { markRaw, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import AppNotificator from '@/components/common/AppNotificator.vue';

const layout = ref();
const route = useRoute();

watch(
    () => route.meta?.layout,
    async (metaLayout) => {
        try {
            const component = metaLayout && await import(`./${metaLayout}.vue`)
            layout.value = markRaw(component?.default || AppLayoutDefault)
        } catch (e) {
            layout.value = markRaw(AppLayoutDefault)
        }
    },
    { immediate: true }
)
</script>

<template>
    <component :is="layout">
        <router-view :key="$route.fullPath" />
        <app-notificator />
    </component>
</template>
