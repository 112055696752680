<script>
import PermissionModals from "@/components/PermissionModals.vue";
import AppLayout from "@/layouts/AppLayout.vue";
import {isWildcard} from "eslint-plugin-vue/lib/utils/indent-utils";
import {useDialog} from "primevue/usedialog";

export default {
    name: 'App',
    components: {AppLayout, PermissionModals},
    data() {
        return {
            runNextMount: false,
        }
    },
    computed: {
        buildVersion() {
            return process.env.VUE_APP_BUILD_VERSION
        },
    },
    errorCaptured(err) {
        const href = location.href.replace(location.origin, '');
        const report = err.message + ' on ' + href;
        window.umami?.track('errorCaptured', {report: report, ua: window.navigator.userAgent, user_id: this.$store.me.id});
    },
    created() {
        const params = new URLSearchParams(location.search);
        if (params.get('s')) sessionStorage.setItem('accessKey', params.get('s')); // Switch short Session
        if (params.get('k')) localStorage.setItem('accessKey', params.get('k'));   // Switch persist session
    },
    async mounted() {
        // @note this.$route.name is undefined! Because this component is outside `<router-view></router-view>`

        await this.$store.loadAdjustments();

        if (!this.$store.accessKey  && !location.pathname.match(/^(\/auth|\/game\/|\/guest\/|\/war-memory)/)) {
            this.$router.replace('/auth');
        }

        if (this.$store.accessKey) {
            await this.$store.auth();
            // @note Це гарантує, що решта компонентів будуть монтуватися вже після отримання `this.$store.me`
            await this.$store.init();

            if (location.pathname === '/auth') {
                this.$router.replace('/');
            }
        }

        this.setupAdjustments();

        if (!this.$store.accessKey) this.runNextMount = true;
        if (this.$store.me.id) this.runNextMount = true;

        if (!this.runNextMount) {
            // Fix: Ініціалізація після "просипання" вкладки
            this.$store.callEveryMinutes('runNextMountRedirect', 5, () => {
                location.reload();
            });
        }

        this.initUmamiTracker(() => {
            // console.log('init umami')
            window.umami?.identify({user_id: this.$store.me.id});
            // window.umami?.track('event-bane', {user_id: this.$store.me.id, ua: window.navigator.userAgent});
        });
    },
    methods: {
        setupAdjustments() {
            if (!this.$store.isAuthenticated) return;

            console.log('setupAdjustments');

            if (this.$store.me.is_teacher) document.body.classList.add('is-teacher');
            if (this.$store.me.is_student) document.body.classList.add('is-student');
            if (this.$store.isDefense) document.body.classList.add('is-defense');

            if (this.$store.adjustments?.background_defense && this.$store.isDefense) {
                //document.querySelector('#app').style.backgroundImage = `url("${this.$store.adjustments.background_common}")`;
                document.documentElement.style.setProperty('--background_defense', `url(${this.$store.adjustments.background_defense})`);
                // Не накладати opacity
                document.querySelector('#app')?.style.setProperty('background-image', `url(${this.$store.adjustments.background_defense})`);
            } else if (this.$store.adjustments?.background_common && !this.$store.isDefense) {
                document.documentElement.style.setProperty('--background_common', `url(${this.$store.adjustments.background_common})`);
            }
        },
        initUmamiTracker(onload) {
            if (process.env.NODE_ENV !== 'production') return;
            if (this.$store.me.is_tester) return;

            // ~2 kb, 250 ms
            if (this.$store.me.is_teacher) {
                const script = document.createElement('script');
                script.defer = true;
                script.src = 'https://umami.edprofi.com.ua/script.js';
                script.setAttribute('data-website-id', '938a269e-9832-4674-ad8a-433af672677b');
                script.onload = onload;
                document.body.appendChild(script);
            } else if (this.$store.me.is_student) {
                const script = document.createElement('script');
                script.defer = true;
                script.src = 'https://umami.edprofi.com.ua/script.js';
                script.setAttribute('data-website-id', '50457cb6-e293-4d6e-8786-594330b0d28c');
                script.onload = onload;
                document.body.appendChild(script);
            }
        }
    },
}
</script>

<template>
    <template v-if="runNextMount">
        <AppLayout />

        <kmm-footer v-if="!['quizGame', 'warMemory', 'warMemoryInfo'].includes(this.$route.name)" />
        <div id="appModals"></div>
        <div :title="'Версія кабінету: ' + buildVersion" style="position: fixed; bottom: 5px; right: 5px; color: #c9c9c9; font-size: 11px">{{ buildVersion }}</div>
        <permission-modals></permission-modals>
    </template>
    <template v-else-if="waitAjaxRef.value > 0">
        <div class="qip-loading" style="height: 100dvh;"></div>
    </template>
</template>

<style>
    :root {
        --background_common: url(@/assets/img/home-bg.svg);
        --background_defense: url(https://s3.edprofi.com.ua/assets/bg-defense-teacher.png);
    }

    .index-bg #app {
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        min-height: 100vh;
        height: 100%;
        width: 100dvw;
    }

    .index-bg:not(.is-defense) #app {
        background-image: var(--background_common);
    }

    .index-bg.is-defense #app {
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), var(--background_defense);
        /*background-image: var(--background_defense), rgba(255, 255, 255, 0.2), rgb(255 255 255 / 95%); */
    }
</style>