<script>
export default {
    name: 'ShelterFloatButton',
    data() {
        return {
            actualMinutes: 5,
            intervalSyncSeconds: 15,
            interval: null,
            info: {}
        }
    },
    computed: {
        isStudyTimeRange() {
            const h = new Date().getHours();
            return h >= 7 && h <= 16;
        },
        isVisible() {
            if (!this.$store.me.is_tester) return false;

            if (!this.$store.me.is_student) return false;
            if (!this.isStudyTimeRange) return false;
            if (!this.info.isActual) return false;

            return true;
        }
    },
    async mounted() {
        if (!this.$store.me.is_tester) return false;

        if (!this.$store.me.is_student) return;

        await this.fetchShelterInfo();
        this.interval = setInterval(async () => {
            if (document.visibilityState === 'hidden') return; // visible | hidden. Згорнута вкладка = 'visible'.
            if (!this.isStudyTimeRange) return;
            await this.fetchShelterInfo();
        }, 1000 * this.intervalSyncSeconds);
    },
    methods: {
        async fetchShelterInfo() {
            this.info = await this.$store.syncGetShelterMaterial().then(r => r.json());
            if (this.info?.timestamp) {
                this.info.activeMinutesAgo = ((Date.now() - new Date(this.info?.timestamp).getTime()) / 1000 / 60).toFixed(1);
                this.info.activeMinutesAgo = parseFloat(this.info.activeMinutesAgo);
                this.info.isActual = this.info.activeMinutesAgo <= this.actualMinutes;
            }
        },
        gotoMaterial() {
            if (!this.info.topic_id || !this.info.material_id) return;
            this.$router.push({name: 'topic', params: {id: this.info.topic_id}, query: {material_id: this.info.material_id}});
        }
    }
}
</script>

<template>
    <div class="shelterFloatButton" v-if="isVisible">
        <button class="qip-button icon qip-animation-pay-attention" @click="gotoMaterial"></button>
    </div>
</template>

<style scoped>
    .shelterFloatButton {
        position: fixed;
        right: 10px;
        bottom: 10px;
        width: 60px;
        height: 60px;
        z-index: 1;

        button {
            width: 100%;
            height: 100%;
            /*background-image: url("https://s3.edprofi.com.ua:9000/assets/shelter-purple.png") !important;
            background-size: contain;
            background-repeat: no-repeat;*/
            background: url("https://s3.edprofi.com.ua:9000/assets/shelter-purple.png") no-repeat center / contain !important;
            filter: none !important;
            /*https://s3.edprofi.com.ua:9000/assets/shelter-purple.png*/
            /*https://s3.edprofi.com.ua:9000/assets/shelter-pink.png*/
            transition: 300ms ease-in-out;

            &:hover { scale: 1.1; }
        }
    }
</style>