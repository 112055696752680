<script>
import {defineComponent} from 'vue';
import ShelterFloatButton from "@/components/shelter/ShelterFloatButton.vue";

export default defineComponent({
    name: 'AppLayoutUser',
    components: {ShelterFloatButton}
})
</script>

<template>
    <main class="wrapper">
        <kmm-nav-defense v-if="$store.isDefense" />
        <kmm-nav v-else />

        <slot />
        <ShelterFloatButton />
    </main>
</template>
