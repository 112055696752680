import { defineAsyncComponent } from 'vue';

export default function (app) {
    app.component('kmm-nav', require('@/components/common/KmmNav.vue').default);
    app.component('nav-sub', require('@/components/common/NavSub.vue').default);
    app.component('kmm-footer', require('@/components/common/KmmFooter.vue').default);

    app.component('app-layout-user', require('@/layouts/AppLayoutUser.vue').default);
    app.component('quiz-bid', require('@/components/quiz/QuizBid.vue').default);

    app.component('qip-modal', defineAsyncComponent(() => import('@/components/qip/QipModal.vue' /* webpackChunkName: "qip-modal" */)));
    app.component('qip-tabs', defineAsyncComponent(() => import('@/components/qip/QipTabs.vue' /* webpackChunkName: "qip-tabs" */)));

    app.component('form-student', defineAsyncComponent(() => import('@/components/FormStudent.vue' /* webpackChunkName: "form-student" */)));
    app.component('materials-fast', defineAsyncComponent(() => import('@/components/MaterialsFast.vue' /* webpackChunkName: "materials-fast" */)));
    app.component('material-player', defineAsyncComponent(() => import('@/components/common/MaterialPlayer.vue' /* webpackChunkName: "material-player" */)));
    app.component('select-book-modal', defineAsyncComponent(() => import('@/components/SelectBookModal.vue' /* webpackChunkName: "select-book-modal" */)));
    app.component('book-planning', defineAsyncComponent(() => import('@/components/BookPlanning.vue' /* webpackChunkName: "book-planning" */)));

    app.component('GrantDiploma', defineAsyncComponent(() => import('@/components/diplomas/GrantDiploma.vue' /* webpackChunkName: "GrantDiploma" */)));

    //app.component('vue-imask', defineAsyncComponent(() => import('https://cdn.jsdelivr.net/npm/vue-imask@7.5.0/dist/vue-imask.min.js' /* webpackChunkName: "vue-imask" */)));

    app.component('kmm-nav-defense', defineAsyncComponent(() => import('@/components/common/KmmNavDefense.vue' /* webpackChunkName: "kmm-nav-defense" */)));
}
