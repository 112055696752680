<script>
export default {
    name: 'NavSub',
    props: {
        group: String
    },
    mounted() {

    }
}
</script>

<template>
    <div class="kmm-card nav-sub">
        <template v-if="group === 'quiz'">
            <router-link :to="{name: 'quizzesRating'}" active-class="current" v-if="$store.me.is_teacher">Рейтинг команд</router-link>
            <router-link :to="{name: 'quizTraining'}" active-class="current">
                Підготовка до Quiz
                <b v-if="$store.me.is_student && $store.me.quiz_training_hit_ids && $store.me.quiz_training_hit_ids.length"
                   style="color: var(--orange); margin-left: 5px; position: absolute;"
                   :title="`Ви переглянули ${$store.me.quiz_training_hit_ids.length} відео`">
                    ☆<sup>{{$store.me.quiz_training_hit_ids.length}}</sup>
                </b>
            </router-link>
            <router-link :to="{name: 'quizCaptain'}" active-class="current" v-if="$store.me.is_student && $store.me.is_captain">Моя команда</router-link>
            <!--<router-link to="/" disabled title="Скоро буде..">Команди</router-link>-->
            <router-link :to="{name: 'quizzes'}" active-class="current" v-if="$store.me.is_teacher">Попередні ігри</router-link>
        </template>
    </div>
</template>

<style>
    a.current, a.current:hover { color: var(--brown); cursor: default; }
    .nav-sub {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        color: var(--primary-text);
        font-weight: 600;
        font-size: 18px;
        padding: 4px;
    }
</style>
