<script>
export default {
    name: 'KmmProfileMenu',
}
</script>

<template>
    <nav class="profile-menu" style="display: flex; justify-content: space-between; margin: 0 -15px 0; padding: 0 1rem; height: 30px;">
        <div></div>
        <div></div>
        <div style="font-weight: 600; font-size: clamp(11px, 3.5dvw, 1.1rem); display: flex; align-items: center; gap: 30px;">
            <div style="color: #703E24" @click="$router.push('/profile')">
                <i style="display: inline-block; font: 80% normal; vertical-align: middle; margin-top: -4px;">💼<!-- 🏫 | 👨‍🎓 | 👨‍🏫 --></i>
                <!--{{ $store.me.is_teacher ? 'Вчитель' : 'Учень' }}:-->
                {{ $store.me.name }}
            </div>
            <div style="white-space: nowrap" @click="$router.push('/profile')"><i style="display: inline-block; font: 90% normal; vertical-align: baseline;">🎓</i> {{ $store.me.class?.name }}</div>
            <div v-if="$store.isDefense" style="margin-right: 20px">
                <a title="Вийти" @click="$store.logout()" class="logout hover-scale">🔐</a>
            </div>
        </div>
    </nav>
</template>

<style scoped>
    .logout { display: block; }
    .hover-scale:hover { transform: scale(1.1); }
</style>